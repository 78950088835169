<template>
  <div>
    <div class="header">
      <button class="btn-img mr-3" @click="link('/terms')">
        <img src="@/assets/icon-terms.png"/>
      </button>      
      <div class="btn-img" @click="logout">
        <img src="@/assets/icon-singout.png"/>
      </div>
    </div>
    <div class="page-title">Settings</div>
    <div class="message"></div>
    <div class="menus">
      <div
        @click="link('/config/textbook')"
        class="menu">
        教科書
      </div>      
      <div
        @click="link('/config/school')"
        class="menu">
        学校
      </div>
      <div
        @click="link('/config/grade')"
        class="menu">
        学年・クラス・番号
      </div>
      <div
        @click="newWindow('myAccount.html')"
        class="menu">
        メールアドレス
      </div>
    </div>
    <div class="btn-row">
      <div
        class="btn-img"
        @click="backToMenu()">
        <img src="@/assets/icon-back.png">
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Term',
  components: {
  },
  computed: {
    ...mapState(['auth',]),
    prontestSeriesUrl() {
      return process.env.VUE_APP_SERIES_URL
    },
  },
  data() {
    return {
    }
  },
  methods: {
    backToMenu() {
      this.$router.push('/menu')
    },
    link(path) {
      this.$router.push(path)
    },
    newWindow(page) {
      const url = `${this.prontestSeriesUrl}redirect.html?refreshToken=${this.auth.refreshToken}&page=${page}`
      window.open(url, '_blank').focus()
    },
    logout() {
      this.$store.commit('clearAuthInfo')
      this.$router.push('/login')
    },    
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: flex-end;
}
.logout {
  float: left;
  width: 100px;
  position: absolute;
  cursor: pointer;
  background: white;
  padding: 5px 10px;
  border-radius: 20px;
  margin-left: 100px;
}
.message {
  height: 30px;
}
.menus {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.menu {
  width: 320px;
  border-radius: 5px;
  color: white;
  margin: 18px 0px;
  padding: 5px 0;
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;
  border: 2px #28529D solid;
  background: linear-gradient(to bottom, #28529D, #0E1D3A);
  box-shadow: 3px 3px 6px 1px rgb(80 80 80 / 30%);  
}

.menu:hover {
    border: 2px rgb(29, 200, 29) solid;
    background: linear-gradient(to bottom, #789ee0, #5773a8);
  }

.btn-row {
  margin-top: 40px;
}
</style>

